// Import React Libraries
import React from 'react';

// Import Components
import Introduction from '../sections/Introduction/Introduction';
import About from '../sections/About/About';
import Contact from '../sections/Contact/Contact';
import Projects from '../sections/Projects/Projects';

import styled from 'styled-components';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function Home () {
  return (
    <HomeWrapper>
      <Introduction/>
      <About/>
      <Projects/>
      <Contact/>
    </HomeWrapper>

  );
}

export default Home;
