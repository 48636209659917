// Import Styled
import styled from 'styled-components';

// Import Constants
import {
  responsiveWidthTablet,
  responsiveWidthMobile
} from '../../Constant';

export const WorkStationPictureContainer = styled.div`
  display: flex;
  margin: 0 50px;
  align-items: center;

  width: 55%;

  @media (max-width: ${responsiveWidthTablet}px){
    justify-content:center;
    margin: auto;
  }
  @media (max-width: ${responsiveWidthMobile}px){
    width: 100%;
  }
`;

export const WorkStationPicture = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 15px;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 100%;
  }
  
`;

export const AboutTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  padding:0;

  font-family: sans-serif;
  width: 65%;

  @media (max-width: ${responsiveWidthTablet}px){
    align-items: center;
    width: 100%;
    margin: auto;
  }
`;
