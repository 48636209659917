// Import React Libraries
import React from 'react';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

// Import Datas
import { contactDatas } from '../../datas/contact';

// Import Styled
import { SocialIconWrapper, StyledAwesomeIcon } from '../../utils/Style/Icons/Icons_Styles';

// Test Reconnexion
function SocialNetworkIconsComponent () {
  return (
    <SocialIconWrapper>
      <a href={contactDatas.SocialMediaLinks.LinkedIn} target="_blank" rel="noopener noreferrer">
        <StyledAwesomeIcon icon={faLinkedin}/>
      </a>
      <a href={contactDatas.SocialMediaLinks.Github} target="_blank" rel="noopener noreferrer">
        <StyledAwesomeIcon icon={faGithub}/>
      </a>
    </SocialIconWrapper>
  );
}
export default SocialNetworkIconsComponent;
