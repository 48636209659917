// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Assets
import { workStation } from '../../datas/illustrations';

// Import Style
import { SectionTitle, SectionSubTitle, StyledP } from '../../utils/Style/Global/Global_Style';
import { SectionContainer, SectionWrapper } from '../../utils/Styles';
import { AboutTextWrapper, WorkStationPicture, WorkStationPictureContainer } from '../../utils/Style/About/About_Style';

function About () {
  const { t } = useTranslation();

  return (
    <SectionWrapper id={`${t('About')}`}>
      <SectionTitle>{t('AboutMeTitle')}</SectionTitle>
      <SectionContainer id='aboutSectionContainer'>
        <WorkStationPictureContainer>
          <WorkStationPicture src={workStation} alt='WorkSpace'/>
        </WorkStationPictureContainer>
        <AboutTextWrapper>
          <SectionSubTitle>{t('AboutMeSubTitle')}</SectionSubTitle>
          <StyledP>{t('AboutMeText1')}</StyledP>
          <StyledP>{t('AboutMeText2')}</StyledP>
          <StyledP>{t('AboutMeText3')}</StyledP>
          <StyledP>{t('AboutMeText4')}</StyledP>
          <StyledP>{t('AboutMeText5')}</StyledP>
          <StyledP>{t('AboutMeText6')}</StyledP>
        </AboutTextWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default About;
