// Import Style
import styled from 'styled-components';

// Import Constants
import {
  responsiveWidthTablet, headerZIndex, headerHeight
} from '../../Constant';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    position: fixed;
    top: 0;
    left:0;

    width: 100%;
    height: ${headerHeight}px;

    padding: 0 10px;
    box-sizing: border-box;

    background: white;

    z-index: ${headerZIndex};

    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: ${({ isHeaderVisible }) => (isHeaderVisible ? '1' : '0')};
    transform: ${({ isHeaderVisible }) => (isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)')};
    pointer-events: ${({ isHeaderVisible }) => (isHeaderVisible ? 'auto' : 'none')}; /* Disable interaction when hidden */
    
    
    @media (max-width: ${responsiveWidthTablet}px){
      padding-top: 5px;
    }
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;
