// Import Style
import styled from 'styled-components';

import { SectionSubTitle } from '../Global/Global_Style';
import { IconContainer } from '../Icons/Icons_Styles';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from '../../Constant';

export const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: ${responsiveWidthMobile}px){
    flex-direction: column;
  }
`;

export const CustomSectionSubTitle = styled(SectionSubTitle)`
  margin-left: 10%;
  @media (max-width: ${responsiveWidthTablet}px){
    margin-left: 0;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 20px 0;

  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column;
    padding: 0;
  }
`;

export const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${responsiveWidthTablet}px){
    text-align:center;
  }
`;

export const CustomIconContainer = styled(IconContainer)`
  margin-right: 30px;

  @media (max-width: ${responsiveWidthTablet}px){
    margin: 0;
  }
`;
