export const navigationMenuContent = [
  {
    SectionLinkTitle: 'Home-Title',
    SectionLink: 'Home'
  },
  {
    SectionLinkTitle: 'About-Title',
    SectionLink: 'About'
  },
  {
    SectionLinkTitle: 'Projects-Title',
    SectionLink: 'Projects'
  },
  {
    SectionLinkTitle: 'Contact-Title',
    SectionLink: 'Contact'
  }
];
