// Import Style
import styled, { css, keyframes } from 'styled-components';

import { SectionSubTitle } from '../Global/Global_Style';
import { IconStyle, StyledAwesomeIcon } from '../Icons/Icons_Styles';

// Import Assets
import FingerPrintHover from '../../../assets/Functionnal_Icon/FingerPrint_Hover.png';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet, verticalMarginBetweenProjectPreview } from '../../Constant';

// Import Colors
import colors from '../../Colors/Colors';

export const PreviewWrapper = styled.div`
  width: 100%;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.index % 2 ? 'row-reverse' : 'row')};
  justify-content: space-around;

  width: 100%;
  margin: ${verticalMarginBetweenProjectPreview}px 0;

  background-color: white;

  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  
  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column;
    align-items: center; 
    height: auto;
  }
`;
export const WebSitePreviewWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: ${responsiveWidthMobile}px){
    flex-direction: column;
  }
`;

export const FingerPrintContainer = styled.div`
  
  display: none;

  @media (max-width: ${responsiveWidthTablet}px){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: 0 20px;

  }
  @media (max-width: ${responsiveWidthMobile}px){
    width: 100%;
    height: 50px;
    margin: 20px 0;
  }
`;

export const pulseAnimation = keyframes`
  0% ,100% {
    height: 49px;
    width: 49px;
  }
  50% {
    height: 45px;
    width: 45px;
  }
`;

export const FingerPrintBorderAnimation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  width: 50px;

  border-radius: 50%;

  animation: ${({ isTouched }) =>
    isTouched
      ? css`none`
      : css`${pulseAnimation} 5s infinite alternate`};
  transition: 1s ease;
`;

export const FingerPrintIcon = styled.img`
  ${IconStyle};
  height: 40px;
  width: 40px;
  
  content: ${({ isTouched }) => isTouched ? `url(${FingerPrintHover})` : ''};
  
`;

export const WebSitePreviewContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  margin: 20px;

  position: relative;
  height: 340px;
  width: 500px;
  overflow: hidden;

  border-radius: 15px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  @media (max-width: ${responsiveWidthMobile}px){
    width: 350px;
  }
  
  @media (max-width: 450px){
    width: 300px;
  }

  @media (max-width: 370px){
    width: 280px;
  }

  
`;

export const WebSitePreviewIMG = styled.img`

  transition: transform 10s linear;
    
  ${WebSitePreviewContainer}:hover & {
    transform: translateY(${(props) => `calc(0% - ${props.imageheight}px + 340px)`});
  }

  transform: ${({ isTouched, imageheight }) => isTouched ? `translateY(calc(0% - ${imageheight}px + 340px))` : ''};
  
  @media (max-width: ${responsiveWidthTablet}px){

  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionWrapper = styled.div`

`;

export const WebSiteDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:40%;
  margin: 15px 0;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 100%;
  }
`;

export const PreviewTitle = styled(SectionSubTitle)`
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  text-align: center;

  @media (max-width: ${responsiveWidthTablet}px){
    margin: 0 20px;
  }
  @media (max-width: ${responsiveWidthMobile}px){
    margin:0;
  }
`;

const CommonText = css`
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
`;

export const PreviewLinkText = styled.p`
  ${CommonText};
  padding: 5px;
  color: inherit;
  @media (max-width: ${responsiveWidthTablet}px){

  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  height: 75px;
  
`;

export const WebSitePreviewLinkContainer = styled.div`
  ${CommonText};
  display: flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 50px;

  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);

  color: ${colors.clickableElement};

  &:hover{
    transform: scale(1.05);
    transition: transform 0.5s ease;
    color: ${colors.clickableElementHover};

    ${StyledAwesomeIcon}{
      color: ${colors.clickableElementHover};
      transform: scale(1);
    }
  }

  @media (max-width: ${responsiveWidthTablet}px){
    width: 100%;
    padding: 0 5px;
  }
  
`;
