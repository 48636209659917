// Import React Libraries
import React, { useEffect, useState } from 'react';
import { faMapLocation, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Import Components
import CopyEmailLink from '../../components/Mail/Mail';

// Import Datas
import { contactDatas } from '../../datas/contact';

// Import Style
import { SectionTitle, StyledP } from '../../utils/Style/Global/Global_Style';
import { SectionWrapper, SectionContainer } from '../../utils/Styles';
import { StyledAwesomeIconTurquoise } from '../../utils/Style/Icons/Icons_Styles';
import {
  ContactWrapper, CustomSectionSubTitle,
  ContactContainer, ContactTextContainer, CustomIconContainer
} from '../../utils/Style/Contact/Contact_Style';

// Import Constants
import { generalTextFontSize, responsiveWidthMobile } from '../../utils/Constant';

// Import Colors
import colors from '../../utils/Colors/Colors';

function Contact () {
  const { t } = useTranslation();
  const [layoutMobile, setLayoutMobile] = useState(false);

  const mailPopUpStyle = {
    fontFamily: 'sans-serif',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    color: `${colors.text}`,
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: `${generalTextFontSize}px`
  };

  useEffect(() => {
    const handleResize = () => {
      // console.log('Window resized!');
      if (window.innerWidth <= responsiveWidthMobile) {
        console.log('TRUE');
        setLayoutMobile(true);
      } else {
        console.log('FALSE');
        setLayoutMobile(false);
      }
    };
    if (window.innerWidth <= responsiveWidthMobile) {
      setLayoutMobile(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    console.log(`layoutMobile : ${layoutMobile}`);
  }, [layoutMobile]);

  return (
    <SectionWrapper id={`${t('Contact')}`}>
      <SectionTitle>{t('Contact')}</SectionTitle>
      {
        layoutMobile
          ? <>
              <CustomSectionSubTitle>{t('ContactMe1')}</CustomSectionSubTitle>
              <CustomSectionSubTitle>{t('ContactMe2')}</CustomSectionSubTitle>
            </>
          : <CustomSectionSubTitle>{`${t('ContactMe1')} ${t('ContactMe2')}`}</CustomSectionSubTitle>
      }
      <SectionContainer>
        <ContactWrapper>
          <ContactContainer>
            <CustomIconContainer>
              <StyledAwesomeIconTurquoise icon={faMapLocation}/>
            </CustomIconContainer>
            <ContactTextContainer>
              <StyledP style={{ fontWeight: 'bold' }}>{t('Location')}</StyledP>
              <StyledP>{contactDatas.Location}</StyledP>
            </ContactTextContainer>
          </ContactContainer>
          <ContactContainer>
            <CustomIconContainer>
              <CopyEmailLink email={contactDatas.Mail} style={mailPopUpStyle} icon={faPaperPlane}/>
            </CustomIconContainer>
            <ContactTextContainer>
              <StyledP style={{ fontWeight: 'bold' }}>{t('EMail')}</StyledP>
              <CopyEmailLink email={contactDatas.Mail} style={mailPopUpStyle}/>
            </ContactTextContainer>
          </ContactContainer>
        </ContactWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}
export default Contact;
