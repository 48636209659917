// Import Styled
import styled from 'styled-components';

// Import Constants
import { responsiveWidthTablet } from '../../Constant';

export const SideBarNavigationUl = styled.ul`

  display: flex;
  gap: 2rem;

  list-style: none;
  text-decoration: none;
  
  padding:0;


  @media (max-width: ${responsiveWidthTablet}px){
      
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding: 3rem 3rem;
    text-align: left;
    margin-top: 0;
    margin-right:0;
    gap: 1rem;
    
    justify-content: top-start;
    
    background: #ffffff;

    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    li{
      margin-bottom: 20px;
      font-size: 25px;
    }
  }
`;
