// Import Style
import styled, { css } from 'styled-components';
import { SectionContainer } from '../../Styles';
import { globalFontFamily } from '../Global/Global_Style';

// Import Constants
import { responsiveWidthTablet } from '../../Constant';

// Import Colors
import colors from '../../Colors/Colors';

export const IntroductionSectionContainer = styled(SectionContainer)`
  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column-reverse;
  }
`;

export const IntroductionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 50px;
  @media (max-width: ${responsiveWidthTablet}px){
    margin: 0;
  }
  gap: 30px;
`;

export const IntroductionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${responsiveWidthTablet}px){
    margin: 0;
  }
  gap: 15px;
`;

const introductionCommonWriting = css`
  ${globalFontFamily}
  font-weight: bold;
  text-align: center;
`;
export const IntroductionTitle = styled.h1`
  ${introductionCommonWriting};
  font-size: 50px;
  margin: 0;
  width: 100%;
  color: ${colors.mainTitle};

`;
export const IntroductionText = styled.p`
  ${introductionCommonWriting};
  font-size: 25px;
  margin: 0;
  width: 100%;
  color: ${colors.text};

`;

export const ProfilePictureContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;

  @media (max-width: ${responsiveWidthTablet}px){
      margin: auto;
  }
`;

export const ProfilePicture = styled.img`
  width: 300px;
  height: 400px;
  border: 2px solid black;
  border-radius: 05% 05% 05% 05%;
  
  transition: border-radius 1s ease-in-out;
`;
