// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

// Import Style
import { StyledP } from '../../utils/Style/Global/Global_Style';
import { CopyrightContainer } from '../../utils/Style/Footer/Footer_Style';

function Copyright () {
  const { t } = useTranslation();
  const currentYear = format(new Date(), 'yyyy');

  return (
    <CopyrightContainer id='copyrightContainer'>
      <StyledP style={{ fontWeight: 'bold' }}>Copyright © {currentYear} {t('AllRightsReserved')}</StyledP>
    </CopyrightContainer>
  );
}
export default Copyright;
