// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Datas
import { SitePreviewDatasListPropTypes } from '../../datas/sites';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style
import { StyledP } from '../../utils/Style/Global/Global_Style';

// Import Constants
import {
  DescriptionContainer,
  DescriptionWrapper,
  PreviewTitle,
  WebSiteDescriptionWrapper
} from '../../utils/Style/Preview/Preview_Style';
import TechInfoDisplay from './TechInfoDisplay';
import LinksPreview from './LinksPreview';

// Import Constants

function DescriptionPreview ({ SiteData }) {
  console.log('SiteData : ', SiteData);
  const { t } = useTranslation();

  return (
    <WebSiteDescriptionWrapper id={`WebsiteDescriptionWrapper-${SiteData.title}`}>
      <DescriptionWrapper id={`DescriptionWrapper-${SiteData.title}`}>
        <PreviewTitle>{t(`${SiteData.title}`)}</PreviewTitle>
        {
          SiteData.description.length > 0 &&
            <DescriptionContainer id='descriptionContainer'>
              {
                SiteData.description.map((description) => (

                  <StyledP key={`${SiteData.title}-${description}`}>{t(`${description}`)}</StyledP>

                ))
              }
            </DescriptionContainer>
        }
      </DescriptionWrapper>
      <TechInfoDisplay SiteData={SiteData}/>
      <LinksPreview SiteData={SiteData}/>
    </WebSiteDescriptionWrapper>
  );
}

DescriptionPreview.propTypes = {
  SiteData: PropTypes.arrayOf(SitePreviewDatasListPropTypes)
};

export default DescriptionPreview;
