// Import React Libraries
import React from 'react';

// Import Styled
import styled from 'styled-components';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Constants
import { navBarZIndex, navHeight, responsiveWidthTablet } from '../../Constant';

const BaseNav = ({ className, children }) => <nav className={className}>{children}</nav>;

BaseNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export const StyledTopBarNavigation = styled(BaseNav)`
    
  height: ${navHeight}px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  z-index: ${navBarZIndex};
  
  transition: background-color 0.3s ease;

  background-color: white;
  color: black;

  @media (max-width: ${responsiveWidthTablet}px){
    padding: 0;
  }
`;
