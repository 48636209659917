// Import React Libraries
import React from 'react';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Import Datas
import { SitePreviewDatasListPropTypes } from '../../datas/sites';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style
import { StyledAwesomeIcon } from '../../utils/Style/Icons/Icons_Styles';

// Import Constants
import {
  WebSitePreviewLinkContainer,
  LinksWrapper,
  PreviewLinkText
} from '../../utils/Style/Preview/Preview_Style';

// Import Constants

function LinksPreview ({ SiteData }) {
  /*
  console.log('LinksPreview');
  console.log('SiteData : ', SiteData);
  */
  const { t } = useTranslation();

  return (
    <LinksWrapper id='linksWrapper'>
      {
        SiteData.linkGit !== '' &&
        <a href={SiteData.linkGit} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <WebSitePreviewLinkContainer>
            <PreviewLinkText>Code</PreviewLinkText>
            <StyledAwesomeIcon icon={faGithub} style={{ height: '30px', width: '30px ' }}/>
          </WebSitePreviewLinkContainer>
        </a>
      }
      {
        SiteData.linkLive !== '' &&
        <a href={SiteData.linkLive} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <WebSitePreviewLinkContainer>
            <PreviewLinkText>{`${t('Visit')}`}</PreviewLinkText>
            <StyledAwesomeIcon icon={faExternalLink} style={{ height: '25px', width: '25px ' }}/>
          </WebSitePreviewLinkContainer>
        </a>
      }
    </LinksWrapper>
  );
}

LinksPreview.propTypes = {
  SiteData: PropTypes.arrayOf(SitePreviewDatasListPropTypes)
};

export default LinksPreview;
