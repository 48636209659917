// Import React Lib
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Translation index
import './i18n';

// Imports Components
import Header from './components/Header/Header';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';

// Import Style
import styled from 'styled-components';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
`;

const ContentWrapper = styled.div`
  
  width: 100%;
  
`;

function App () {
  const { i18n } = useTranslation();
  return (
  <React.StrictMode>
    <Router>
      <Header/>
      <HomeWrapper>
        <ContentWrapper>
          <Routes>
            <Route path={`/${i18n.language}/`} element={<Home />} />
          </Routes>
        </ContentWrapper>
      </HomeWrapper>
      <Footer/>
    </Router>

  </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
