// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropType
import PropTypes from 'prop-types';

// Import Datas
import { navigationMenuContent } from '../../datas/navigation';

// Import Functions
import { scrollToSection, scrollToTop } from '../../utils/Functions/Scroll_Functions';

// Import Style
import { SideBarNavigationUl } from '../../utils/Style/Navigation/SideBarNavigation_Style';
import { StyledLink } from '../../utils/Styles';

// Import constants

function SideBarNavigation ({ open, handleCloseBurger }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  function handleLinkClick (event) {
    // const id = event.currentTarget.getAttribute('href').substring(1);
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');
    const id = href.split('#')[1];
    console.log('id : ', id);
    scrollToSection(id);
    handleCloseBurger();
    if (id !== `${t('Home')}`) {
      navigate(`/${i18n.language}/#${id}`, { replace: false });
    } else {
      navigate(`/${i18n.language}`, { replace: false });
      scrollToTop();
    }
  }

  return (
    <SideBarNavigationUl open={open}>
      {
        navigationMenuContent.map((option) => (
          <li key={option.SectionLink} id={`SideBarNavigationUl : ${option.SectionLink}`}>
            <StyledLink to={`/${i18n.language}/#${t(option.SectionLink)}`} onClick={handleLinkClick}>
              {t(option.SectionLinkTitle)}
            </StyledLink>
          </li>
        ))
      }
    </SideBarNavigationUl>
  );
}

SideBarNavigation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseBurger: PropTypes.func.isRequired
};

export default SideBarNavigation;
