// Import Style
import styled, { css } from 'styled-components';
import { IconStyle } from './Icons/Icons_Styles';

// Import Constants
import { responsiveWidthTablet } from '../Constant';
import { commonWriting } from './Global/Global_Style';

// Import Colors
import colors from '../Colors/Colors';

export const TechStackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Text Related
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  width: 150px;
`;

const TechStackEffect = css`
  position: relative;
  padding: 5px;
  font-size: 25px;

  &::before,&::after {
    content: '';
    position: absolute;
    width: 25%;
    height: 50%;
    border: 2px solid black;
    box-sizing: border-box;
    transition: all 0.5s ease;
  }

  &::before {
    bottom: 0;
    left: 0;
    border-color: transparent transparent ${colors.text} ${colors.text};
  }

  &:hover::before {
    bottom: -5px;
    left: -5px;
  }

  &::after {
    top: 0;
    right: 0;
    border-color: ${colors.text} ${colors.text} transparent transparent;
  }

  &:hover::after {
    top: -5px;
    right: -5px;
  }
`;

export const TechStackP = styled.p`
  ${commonWriting};
  ${TechStackEffect};
  color: ${colors.text};
`;

// Icon Related
export const TechStackIconWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 400px;
  margin-top: 20px;

  @media (max-width: ${responsiveWidthTablet}px){
      width: 100%;
      text-align:center;
      margin-top: 0;
  }
`;

export const TechStackIconContainer = styled.div`
  transition: transform 0.5s ease-in-out;

  &:hover{
    transform: translateY(-1rem);
  }
  @media (max-width: ${responsiveWidthTablet}px){
    justify-content: center;
  }
`;

export const TechIcon = styled.img`
  ${IconStyle};
  padding: 10px;
  &:hover {
      cursor: default;
  }
`;
