// Import React Libraries
import React, { useState, useEffect, useRef } from 'react';

// Import Component
import SideBarNavigation from './SideBarNavigation';

// Import Style
import {
  BurgerContainer,
  StyledBurger
} from '../../utils/Style/Navigation/BurgerButton_Style';

function BurgerButton () {
  const [open, setOpen] = useState(false);
  const burgerRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    function handleClickOutside (event) {
      if (burgerRef.current && !burgerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Function to close the Burger component
  function handleCloseBurger () {
    setOpen(false);
  }

  return (
    <BurgerContainer ref={burgerRef} id='burgerContainer'>
      <StyledBurger id='burgerButton' open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <SideBarNavigation open={open} handleCloseBurger={handleCloseBurger}/>
    </BurgerContainer>
  );
}

export default BurgerButton;
