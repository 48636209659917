// Import React Libraries
import { Link } from 'react-router-dom';

// Import Style
import styled, { css } from 'styled-components';

// Impor Colors
import colors from './Colors/Colors';

// Import Constants
import {
  sectionWrapperMarginVertical,
  responsiveWidthTablet,
  responsiveWidthMobile,
  sectionWrapperPaddingVertical
} from './Constant';
import { commonWriting } from './Style/Global/Global_Style';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${sectionWrapperMarginVertical}px auto;
  width: 100%;

  padding: ${sectionWrapperPaddingVertical}px 0;

  @media (max-width: ${responsiveWidthTablet}px){
    padding: ${sectionWrapperPaddingVertical}px 0;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    padding: ${sectionWrapperPaddingVertical}px 0;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-around;

  width: 85%;
  margin: 0 auto;
  
  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column;
  }
  @media (max-width: ${responsiveWidthTablet}px){
  }
`;

export const ComponentBorder = css`
  border-radius:15px;
`;

const commonLink = css`
  text-decoration: none;
  color: ${colors.clickableElement};

  &:hover {
    color: ${colors.clickableElementHover};
  } 
`;

export const StyledLink = styled(Link)`
  ${commonWriting};
  ${commonLink};
`;

export const StyledLi = styled.li`
  list-style:none;
`;

export const StyledSpanHeader = styled.span`
  ${commonWriting};
`;
