// Import Style
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Constants
import { footerSideDivWidth, responsiveWidthTablet } from '../../Constant';

// Imports Colors
import colors from '../../Colors/Colors';

export const IconContainerStyle = css`
    
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  @media (max-width: ${responsiveWidthTablet}px){
    text-align:center;
    margin:0;
  }
`;
export const IconContainer = styled.div`
    ${IconContainerStyle};
`;

export const IconStyle = css`
  height: 45px;
  width: 45px;

  transition: transform 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const StyledAwesomeIcon = styled(FontAwesomeIcon)`
  ${IconStyle};
  color: ${colors.clickableElement};

  &:hover {
    color: ${colors.clickableElementHover};
  }
`;

export const StyledAwesomeIconTurquoise = styled(FontAwesomeIcon)`
  ${IconStyle};
  height: 35px;
  width: 35px;
  color: ${colors.clickableElement};
  &:hover {
    cursor: pointer;
  }
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: ${footerSideDivWidth}px;
  
  @media (max-width: ${responsiveWidthTablet}px){
    justify-content: space-evenly;
  }
  border
`;
