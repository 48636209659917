// Import React Libraries
import React, { useEffect, useState } from 'react';

// Import Component
import SocialNetworkIconsComponent from '../SocialNetworkIcons/SocialNewtorkIcons';
import ScrollToTopComponent from './ScrollToTopComponent';

// Import Style
import {
  FooterWrapper,
  MobileDisplayWrapper
} from '../../utils/Style/Footer/Footer_Style';

// Import Constants
import { responsiveWidthMobile } from '../../utils/Constant';
import Copyright from './CopyRight';

function Footer () {
  const [footerLayoutMobile, setFooterLayoutMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      console.log('Window resized!');
      if (window.innerWidth <= responsiveWidthMobile) {
        setFooterLayoutMobile(true);
      } else {
        setFooterLayoutMobile(false);
      }
    };
    if (window.innerWidth <= responsiveWidthMobile) {
      setFooterLayoutMobile(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return (
    <FooterWrapper id='footerWrapper'>
      {footerLayoutMobile
        ? <>
            <MobileDisplayWrapper id='mobileDisplayWrapper'>
              <SocialNetworkIconsComponent/>
              <ScrollToTopComponent/>
            </MobileDisplayWrapper>
            <Copyright/>
          </>
        : <>
            <SocialNetworkIconsComponent/>
            <Copyright/>
            <ScrollToTopComponent/>
          </>
      }
    </FooterWrapper>

  );
}
export default Footer;
