// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import Datas
import { LANGUAGES, responsiveWidthMobile } from '../../utils/Constant';

// Import Functions
import { pathWithoutLang, translatePath } from '../../utils/Functions/Path_Functions';
import { scrollToSection } from '../../utils/Functions/Scroll_Functions';

// Import Style
import { LanguageMenuContainer, StyledSelect } from '../../utils/Style/LanguageMenu/LanguageMenu_Style';

export const LanguageMenu = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [layoutMobile, setLayoutMobile] = useState(false);

  const onChangeLang = (selectedOption) => {
    const langCode = selectedOption.value;
    // console.log(`langCode : ${langCode}`);
    i18n.changeLanguage(langCode);
  };

  useEffect(() => {
    const initializeI18n = async () => {
      const currentPathname = window.location.pathname;
      const currentPathnameWithoutLang = pathWithoutLang(currentPathname);

      /*
      console.log(`i18n.language : ${i18n.language}`);
      console.log(`currentPathname : ${currentPathname}`);
      console.log(`currentPathnameWithoutLang : ${currentPathnameWithoutLang}`);
      */

      const newPathname = `${i18n.language}/#${translatePath(currentPathnameWithoutLang, t)}`;
      navigate(newPathname, { replace: true });

      const sectionToScrollTo = `${translatePath(currentPathnameWithoutLang, t)}`;

      /*
      console.log(`newPathname : ${newPathname}`);
      console.log(`sectionToScrollTo : ${sectionToScrollTo}`);
      */

      // Delay the scroll to wait for DOM update
      const scrollTimeout = setTimeout(() => {
        scrollToSection(sectionToScrollTo, true);
      }, 2);

      return () => clearTimeout(scrollTimeout);
    };

    initializeI18n();
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= responsiveWidthMobile) {
        setLayoutMobile(true);
      } else {
        setLayoutMobile(false);
      }
    };
    if (window.innerWidth <= responsiveWidthMobile) {
      setLayoutMobile(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  // Create an array of custom options with images
  const customOptions = LANGUAGES.map(({ code, label, flag }) => ({
    value: code,
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: layoutMobile ? 'center' : 'space-between'
        }}
      >
        <img src={flag} alt={label} style={{ height: '25px', width: '40px' }} />
        {
          layoutMobile
            ? <></>
            : <span>{label}</span>
        }
      </div>
    )
  }));

  const customSelectRender = LANGUAGES.map(({ code, label, flag }) => ({
    value: code,
    label: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={flag} alt={label} style={{ height: '25px', width: '40px' }} />
      </div>
    )
  }));

  const selectedLang = customSelectRender.find((option) => option.value === i18n.language);
  // console.log(`selectedLang : ${selectedLang}`);

  return (
    <LanguageMenuContainer id='languageMenuContainer'>
      <StyledSelect
        id='LanguageMenuSelect'
        options={customOptions}

        value={selectedLang}
        onChange={onChangeLang}
      />
    </LanguageMenuContainer>
  );
};
