// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Datas
import { SitePreviewDatasListPropTypes } from '../../datas/sites';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style

// Import Constants
import {
  TechInfoWrapper, TechInfoContainer,
  TechTitle, TechText
} from '../../utils/Style/Preview/Tech_Style';
import { InfoWrapper } from '../../utils/Style/Preview/Preview_Style';

// Import Constants

function TechInfoDisplay ({ SiteData }) {
  console.log('SiteData : ', SiteData);
  const { t } = useTranslation();

  return (
    <InfoWrapper>
        {
          SiteData.tech.UserInterface.length > 0 &&
            <TechInfoWrapper>
              <TechTitle>{`${t('UserInterface')} :`}</TechTitle>
              <TechInfoContainer id={`InforContainer-${SiteData.title}`}>
                {
                  SiteData.tech.UserInterface.map((tech, index) => (
                    <>
                      <TechText key={`${SiteData.title}-${tech}`}>{index !== 0 ? '- ' : ''}{tech}</TechText>
                    </>

                  ))
                }
              </TechInfoContainer>
            </TechInfoWrapper>
        }
        {
          SiteData.tech.Server.length > 0 &&
            <TechInfoWrapper>
              <TechTitle>{`${t('Server')} :`}</TechTitle>
              <TechInfoContainer id={`InforContainer-${SiteData.title}`}>
                {
                  SiteData.tech.Server.map((tech, index) => (
                    <>
                      <TechText key={`${SiteData.title}-${tech}`}>{index !== 0 ? '- ' : ''}{tech}</TechText>
                    </>

                  ))
                }
              </TechInfoContainer>
            </TechInfoWrapper>
        }
        {
          SiteData.tech.Database.length > 0 &&
            <TechInfoWrapper>
              <TechTitle>{`${t('Database')} :`}</TechTitle>
              <TechInfoContainer id={`InforContainer-${SiteData.title}`}>
                {
                  SiteData.tech.Database.map((tech, index) => (
                    <>
                      <TechText key={`${SiteData.title}-${tech}`}>{index !== 0 ? '- ' : ''}{tech}</TechText>
                    </>

                  ))
                }
              </TechInfoContainer>
            </TechInfoWrapper>
        }
      </InfoWrapper>
  );
}

TechInfoDisplay.propTypes = {
  SiteData: PropTypes.arrayOf(SitePreviewDatasListPropTypes)
};

export default TechInfoDisplay;
