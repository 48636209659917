// Import React and hooks
import React, { useRef, useState } from 'react';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Styles and utilities
import { StyledAwesomeIconTurquoise } from '../../utils/Style/Icons/Icons_Styles';
import { generalTextFontSize } from '../../utils/Constant';
import colors from '../../utils/Colors/Colors';
import { useTranslation } from 'react-i18next';

function CopyEmailLink ({ email, icon, style }) {
  const [isCopied, setIsCopied] = useState(false);
  const emailLinkRef = useRef(null);
  const { t } = useTranslation();

  // Function to copy email to clipboard
  const copyToClipboard = (e) => {
    e.preventDefault();
    const emailToCopy = email; // Always use the email prop for copying

    navigator.clipboard.writeText(emailToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  // Function to render icon or email content
  const renderContent = () => {
    if (icon) {
      return <StyledAwesomeIconTurquoise icon={icon} />; // Display icon if provided
    } else {
      return email; // Display email if no icon
    }
  };

  // Determine text color based on icon presence
  const textColor = icon ? colors.clickableElement : colors.text;

  return (
    <div>
      <a
        style={{
          textDecoration: 'none',
          color: textColor,
          fontSize: `${generalTextFontSize}px`,
          fontFamily: 'sans-serif',
          margin: '5px',
          cursor: 'pointer'
        }}
        href={`mailto:${email}`}
        ref={emailLinkRef}
        onClick={copyToClipboard}
      >
        {renderContent()}
      </a>
      {isCopied && (
        <span
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px',
            zIndex: 1000,
            ...style // Apply custom styles
          }}
        >
          {`${t('EmailCopiedToClipboard')}`} {/* Translation for 'Email Copied to Clipboard' */}
        </span>
      )}
    </div>
  );
};

// PropTypes for validation
const MailPopUpStyleType = PropTypes.shape({
  fontFamily: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired
});

CopyEmailLink.propTypes = {
  email: PropTypes.string.isRequired,
  style: MailPopUpStyleType,
  icon: PropTypes.string
};

// Default prop values
CopyEmailLink.defaultProps = {
  style: {
    fontFamily: 'Arial',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '5px',
    fontWeight: 'normal'
  }
};

export default CopyEmailLink;
