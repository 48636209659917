// Import Colors
import { colorsPalette } from './Colors_Palette';

const colors = {
  unevenSections: colorsPalette.platinum,
  evenSections: colorsPalette.white,

  clickableElement: colorsPalette.moonstone,
  clickableElementHover: colorsPalette.paynesGray,

  mainTitle: colorsPalette.marianBlue,
  sectionTitle: colorsPalette.turquoise,
  sectionSubTitle: colorsPalette.marianBlue,
  text: colorsPalette.marianBlue
  // colorsPalette.charcoal
};

export default colors;
