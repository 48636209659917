export const colorsPalette =
{
  white: '#FFFFFF',
  platinum: '#DDE4E3',
  silver: '#B1B4B5',

  turquoise: '#2DC8C5',
  paynesGray: '#496A81',
  moonstone: '#66999B',
  marianBlue: '#364A81',
  marineBlue: '#2202f2',
  lapisLazuli: '#38618C',

  charcoal: '#424B54',
  onyx: '#393E41',
  smokyBlack: '#0B0A07'
};
/* https://coolors.co/38618c-b1b4b5-cdd7d6-0b0a07-ffffff */
