// Import Style
import styled from 'styled-components';

// Import React Components
import Select from 'react-select';

// Import Constant
import { langageMenuWidth, langageMenuZIndex, responsiveWidthMobile } from '../../Constant';

export const StyledSelect = styled(Select)`

  @media (max-width: ${responsiveWidthMobile}px){
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
    .css-1xc3v61-indicatorContainer {
      display: none;
    }
    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-13cymwt-control {
      border: none;
    }    
  }
`;

export const LanguageMenuContainer = styled.div`
  width: ${langageMenuWidth}px;
  z-index: ${langageMenuZIndex};
`;
