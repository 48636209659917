// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Component
import Name from './Name/Name';
import TopBarNavigation from '../Navigation/TopBarNavigation';
import { LanguageMenu } from '../LanguageMenu/LanguageMenu';

// Import Function
import { scrollToTop } from '../../utils/Functions/Scroll_Functions';

// Import Style
import { HeaderContainer, HeaderWrapper } from '../../utils/Style/Header/Header_Style';
import { StyledLink } from '../../utils/Styles';

// Import Constant
import { responsiveWidthTablet } from '../../utils/Constant';

// Import Constants

function Header () {
  const { i18n } = useTranslation();
  const [layoutTablet, setLayoutTablet] = useState(false);

  const headerRef = useRef(null);
  const lastScrollY = useRef(0);
  const isHeaderVisible = useRef(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= responsiveWidthTablet) {
        setLayoutTablet(true);
      } else {
        setLayoutTablet(false);
      }
    };
    if (window.innerWidth <= responsiveWidthTablet) {
      setLayoutTablet(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY.current && isHeaderVisible.current) {
        // Scrolling down and the header is visible, so hide it
        headerRef.current.style.transform = 'translateY(-200px)';
        isHeaderVisible.current = false;
      } else if (currentScrollY < lastScrollY.current && !isHeaderVisible.current) {
        // Scrolling up and the header is hidden, so show it
        headerRef.current.style.transform = 'translateY(0)';
        isHeaderVisible.current = true;
      }

      lastScrollY.current = currentScrollY;
    };

    const handleWheelScroll = (e) => {
      if (e.deltaY > 0) {
        headerRef.current.style.transform = 'translateY(-200px)';
        isHeaderVisible.current = false;
      } else if (e.deltaY < 0) {
        headerRef.current.style.transform = 'translateY(0)';
        isHeaderVisible.current = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleWheelScroll); // Listen for scroll actions

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleWheelScroll); // Cleanup the event listener
    };
  }, []);

  return (
    <HeaderWrapper id='headerWrapper' isHeaderVisible={isHeaderVisible} ref={headerRef}>
      {layoutTablet
        ? <>
            <LanguageMenu/>
            <StyledLink to={`/${i18n.language}`} onClick={() => scrollToTop()}><Name/></StyledLink>
            <TopBarNavigation/>
          </>
        : <>
            <StyledLink to={`/${i18n.language}`} onClick={() => scrollToTop()}><Name/></StyledLink>
            <HeaderContainer id='headerContainer'>
              <TopBarNavigation/>
                <LanguageMenu/>
            </HeaderContainer>
          </>
      }
    </HeaderWrapper>
  );
}

export default Header;
