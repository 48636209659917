// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';

// Import Assets
import FingerPrintClickable from '../../assets/Functionnal_Icon/FingerPrint_Clickable.png';

// Import Datas
import { SitePreviewDatasListPropTypes } from '../../datas/sites';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style

// Import Constants
import { verticalMarginBetweenProjectPreview } from '../../utils/Constant';
import {
  FingerPrintBorderAnimation, FingerPrintContainer,
  FingerPrintIcon,
  PreviewContainer, PreviewWrapper,
  WebSitePreviewContainer,
  WebSitePreviewIMG, WebSitePreviewWrapper
} from '../../utils/Style/Preview/Preview_Style';
import DescriptionPreview from './DescriptionPreview';

// Import Constants

function Preview ({ SiteDatasList }) {
  // const [imageHeights, setImageHeights] = useState(Array(SiteDatasList.length).fill(0));
  const [imageHeights, setImageHeights] = useState([4224, 2545]);
  console.log('imageHeights : ', imageHeights);
  const imgRefs = useRef([]);
  const [indexUsed, setIndexUsed] = useState();
  const [isTouchedStates, setIsTouchedStates] = useState(Array(SiteDatasList.length).fill(false));

  const handleTouchStart = (index) => {
    setIsTouchedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      // console.log('handleTouchStart');
      // console.log(`newStates : ${newStates}`);
      setIndexUsed(index);
      return newStates;
    });
  };

  const handleTouchEnd = (index) => {
    setIsTouchedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      // console.log('handleTouchEnd');
      // console.log(`newStates : ${newStates}`);
      setIndexUsed(index);
      return newStates;
    });
  };

  useEffect(() => {
    // This function gets the height of the image once it is loaded
    const handleImageLoad = () => {
      const currentImageHeight = imgRefs.current[indexUsed].clientHeight;
      setImageHeights((prevHeights) => {
        const newHeights = [...prevHeights];
        newHeights[indexUsed] = currentImageHeight;
        return newHeights;
      });
    };

    if (imgRefs.current[indexUsed]) {
      imgRefs.current[indexUsed].addEventListener('load', handleImageLoad);

      if (imgRefs.current[indexUsed].complete) {
        handleImageLoad();
      }
    }
    console.log(`imageHeights : ${imageHeights}`);
    // console.log(`imgRef.current : ${imgRefs.current}`);
    // console.log(`indexUsed : ${indexUsed}`);
    // Cleanup: Remove the event listener on component unmount to avoid memory leaks
    return () => {
      if (imgRefs.current[indexUsed]) {
        imgRefs.current[indexUsed].removeEventListener('load', handleImageLoad);
      }
    };
  }, [imgRefs.current, indexUsed, isTouchedStates]);

  const handleContextMenu = (e) => {
    e.preventDefault();
    // console.log('preventDefault');
  };

  const handleOverImage = (index) => {
    // console.log('handleOverImage');
    setIndexUsed(index);
  };

  const handleLeaveImage = () => {
    // console.log('handleOverImage');
    setIndexUsed(undefined);
  };

  return (
  <PreviewWrapper id='PreviewWrapper'>
   {
    SiteDatasList.map((siteData, index) => (
      <PreviewContainer
        key={siteData.title}
        id={`PreviewContainer_${siteData.title}`}
        index={index}
        style=
          {{
            marginTop: index === 0 ? '0' : `${verticalMarginBetweenProjectPreview}px`,
            marginBottom: index === SiteDatasList.length - 1 ? '0' : `${verticalMarginBetweenProjectPreview}px`
          }}
      >
        <WebSitePreviewWrapper>
          <a href={siteData.linkLive} target="_blank" rel="noopener noreferrer">
            <WebSitePreviewContainer onMouseOver={() => handleOverImage(index)} onMouseLeave={() => handleLeaveImage()}>
              <WebSitePreviewIMG
                ref={(el) => (imgRefs.current[index] = el)}
                src={siteData.illustration} alt='siteIllustration'
                imageheight={imageHeights[index]}
                isTouched={isTouchedStates[index]}
              />
            </WebSitePreviewContainer>
          </a>
          <FingerPrintContainer
            onContextMenu={handleContextMenu}
            onTouchStart={() => handleTouchStart(index)}
            onTouchEnd={() => handleTouchEnd(index)}
          >
            <FingerPrintBorderAnimation isTouched={isTouchedStates[index]}>
              <FingerPrintIcon src={FingerPrintClickable} alt='overview' onContextMenu={handleContextMenu} isTouched={isTouchedStates[index]}/>
            </FingerPrintBorderAnimation>
          </FingerPrintContainer>
        </WebSitePreviewWrapper>

        <DescriptionPreview SiteData={siteData}/>
     </PreviewContainer>
    ))}
  </PreviewWrapper>

  );
}
// imageheight={imageHeights[index] === 0 ? undefined : imageHeights[index]}

Preview.propTypes = {
  SiteDatasList: PropTypes.arrayOf(SitePreviewDatasListPropTypes)
};

export default Preview;
