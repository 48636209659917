export function scrollToTop () {
  console.log('scrollToTop');
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export function scrollToSection (sectionId, auto = false) {
  const section = document.getElementById(sectionId);

  if (section) {
    if (auto === false) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else if (auto === true) {
      section.scrollIntoView({ behavior: 'auto' });
    }
  }
  if (!section) {
    console.error(`Section with id ${sectionId} not found`);
  }
};
