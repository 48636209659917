// Import React Libraries
import React from 'react';

// Import Component
import BurgerButton from './BurgerButton';

// Import Style
import { StyledTopBarNavigation } from '../../utils/Style/Navigation/TopBarNavigation_Style';

function TopBarNavigation () {
  return (
    <StyledTopBarNavigation>
      <BurgerButton/>
    </StyledTopBarNavigation>
  );
}

export default TopBarNavigation;
