// Import Style
import styled from 'styled-components';

// Import Constants
import { burgerButtonZIndex, langageMenuWidth, responsiveWidthTablet } from '../../Constant';

// Import Colors
import colors from '../../Colors/Colors';

export const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;

  border-radius: 5px;
  padding: 2px;

  z-index: ${burgerButtonZIndex};

  display: none;

  @media (max-width: ${responsiveWidthTablet}px){
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  }

  div{
      
    height: 0.2rem;
    width: 2rem;
    
    border-radius: 10px;
    border: 1px solid ${colors.clickableElement};
    
    transform-origin: 1px;

    &:nth-child(1){
        transform: ${({ open }) => open ? 'translate(14%) rotate(45deg)' : 'translate(0) rotate(0)'};
    }

    &:nth-child(2){
        transform: ${({ open }) => open ? 'translate(100%)' : 'translate(0)'};
        opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3){
        transform: ${({ open }) => open ? 'translate(14%) rotate(-45deg)' : 'translate(0) rotate(0)'};
    }

    @media (max-width: ${responsiveWidthTablet}px){
      background-color: ${colors.clickableElement};;
    }
  }
`;

export const BurgerContainer = styled.div`
  @media (max-width: ${responsiveWidthTablet}px){
    width: ${langageMenuWidth}px;
    display: flex;
    justify-content: center;
  }
`;
