// Import Style
import styled from 'styled-components';

// Import Assets

// Import Constants
import { generalTextFontSize, responsiveWidthTablet } from '../../Constant';
import { globalFontFamily } from '../Global/Global_Style';
import colors from '../../Colors/Colors';

export const TechInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column;
    justify-content: center;
  }
`;

export const TechInfoContainer = styled.div`
  display:flex;
  align-items: center;
  height: 20px;

  @media (max-width: ${responsiveWidthTablet}px){
  }
`;

export const TechTitle = styled.p`
  ${globalFontFamily};
  font-size: ${generalTextFontSize}px;
  font-weight: bold;
  color: ${colors.text};
  margin: 3px;
  @media (max-width: ${responsiveWidthTablet}px){

  }
`;

export const TechText = styled.p`
  ${globalFontFamily};
  font-size: ${generalTextFontSize}px;
  font-style: italic;
  color: ${colors.text};
  margin: 3px;
  @media (max-width: ${responsiveWidthTablet}px){

  }
`;
