// Import Style
import styled, { css } from 'styled-components';

// Import Constants
import { generalTextFontSize, responsiveWidthTablet } from '../../Constant';

// Import Colors
import colors from '../../Colors/Colors';

// Import Constants

export const globalFontFamily = css`
  font-family: sans-serif;
`;

export const globalTextColor = css`
  color: ${colors.text};
`;

export const commonWriting = css`
  ${globalFontFamily};
  font-weight: bold;
  font-size: 25px;

  @media (max-width: ${responsiveWidthTablet}px){
    
  }
`;

export const SectionTitle = styled.h1`

  margin-left: 5%;
  
  font-family:  sans-serif;
  font-weight:bold;
  
  font-size: 50px;

  color: ${colors.sectionTitle};

  @media (max-width: ${responsiveWidthTablet}px){
    margin: auto;
    margin-bottom: 30px;
  }
`;

export const SectionSubTitle = styled.h2`
  ${commonWriting};
  margin-top:0;
  font-size: 30px;
  color: ${colors.sectionSubTitle};

  @media (max-width: ${responsiveWidthTablet}px){
    text-align: center;
  }
`;

export const StyledP = styled.p`
  ${globalFontFamily};
  font-size: ${generalTextFontSize}px;
  margin: 5px;
  color: ${colors.text};
`;
