// Import React Libraries
import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Import Function
import { scrollToTop } from '../../utils/Functions/Scroll_Functions';

// Import Style
import { ScrollToTopButtonContainer, ScrollToTopButton } from '../../utils/Style/Footer/Footer_Style';
import { StyledAwesomeIcon } from '../../utils/Style/Icons/Icons_Styles';

function ScrollToTopComponent () {
  return (
    <ScrollToTopButtonContainer id='scrollToTopButtonContainer'>
      <ScrollToTopButton id="scrollToTopButton" onClick={() => scrollToTop()} >
        <StyledAwesomeIcon icon={faChevronUp}/>
      </ScrollToTopButton>
    </ScrollToTopButtonContainer>
  );
}
export default ScrollToTopComponent;
