// Langage
import flagFr from '../assets/Flag/flag_fr.png';
import flagEn from '../assets/Flag/flag_en.png';

export const LANGUAGES = [
  {
    label: 'FR',
    code: 'fr',
    flag: flagFr
  },
  {
    label: 'EN',
    code: 'en',
    flag: flagEn
  }
];

export const webSiteDevUrl = 'http://localhost:3000';
export const webSiteProdUrl = 'https://www.alibertjimmy.com';

// Responsive Configuration
export const responsiveWidthMobile = 800;
export const responsiveWidthTablet = 950;

// Section Wrapper
export const sectionWrapperPaddingVertical = 75;

export const sectionWrapperMarginVertical = 50;

export const profilePicSize = 400;
export const profilePicSizeResponsive = 200;

// Projects Preview
export const verticalMarginBetweenProjectPreview = 75;

// Global Text Font Size
export const titleFontSize = 50;
export const subTitleFontSize = 30;
export const generalTextFontSize = 18;

// Footer
export const footerSideDivWidth = 150; // Also used by socialNetworkIcons

// Nav
export const navHeight = 50;
export const navPadding = 10;

// Header
export const headerHeight = navHeight + (navPadding * 2);
// Langage Menu
export const langageMenuWidth = 100;

// Z-index:
export const headerZIndex = 99;
export const navBarZIndex = 1;
export const burgerButtonZIndex = 20;
export const langageMenuZIndex = 20;
