// Import React Libraries
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
import SocialNetworkIconsComponent from '../../components/SocialNetworkIcons/SocialNewtorkIcons';
import TechStackComponent from '../../components/TechStack/TechStack';

// Import Functions
import { borderRadiusSequence, deformBorders } from '../../utils/Functions/BorderEffect_Functions';

// Import Assets
import ProfilePic from '../../assets/Profile_Picture/profile_pic.jpg';

// Import Data
import { contactDatas } from '../../datas/contact';

// Import Style
import { SectionWrapper } from '../../utils/Styles';
import {
  IntroductionSectionContainer,
  IntroductionTextWrapper,
  IntroductionTitle,
  IntroductionTextContainer, IntroductionText,
  ProfilePictureContainer, ProfilePicture
} from '../../utils/Style/Introduction/Introduction_Style';

// Import Colors
import colors from '../../utils/Colors/Colors';

// Import Constants

function Presentation () {
  const { i18n, t } = useTranslation();

  const profilePictureRef = useRef(null);
  const counterRef = useRef(0);

  useEffect(() => {
    const profilePicture = profilePictureRef.current;
    if (profilePicture) {
      const deformInterval = setInterval(() => {
        deformBorders(profilePicture, counterRef.current);

        counterRef.current = (counterRef.current + 1);

        if (counterRef.current > borderRadiusSequence.length - 1) {
          counterRef.current = 0;
        }
      }, 750);
      // Cleanup interval on component unmount
      return () => clearInterval(deformInterval);
    }
  }, []);

  useEffect(() => {
  }, [i18n.language]);

  return (
    <SectionWrapper id={`${t('Home')}`}style={{ background: `${colors.unevenSections}` }}>
      <IntroductionSectionContainer id='IntroductionSectionContainer'>
        <IntroductionTextWrapper id='IntroductionTextWrapper'>
          <IntroductionTitle>{t('PresentationTitle')}</IntroductionTitle>
          <IntroductionTextContainer id='IntroductionTextContainer'>
            <IntroductionText>{t('PresentationText1')}</IntroductionText>
            <IntroductionText>{t('PresentationText2')}</IntroductionText>
            <IntroductionText>{t('PresentationText3')}{contactDatas.Location}</IntroductionText>
          </IntroductionTextContainer>
          <SocialNetworkIconsComponent/>
          <TechStackComponent/>
        </IntroductionTextWrapper>
        <ProfilePictureContainer>
          <ProfilePicture src={ProfilePic} alt='profilePic' id='profilePicture' ref={profilePictureRef}/>
        </ProfilePictureContainer>
      </IntroductionSectionContainer>
    </SectionWrapper>
  );
}
export default Presentation;
