// Import React Libraries
import React, { useEffect, useState } from 'react';

// Import Asset
import LogoCompass from '../../../assets/Logo/Logo.png';
import LogoCompassHover from '../../../assets/Logo/Logo_Hover.png';

// Import Styles
import styled from 'styled-components';
import { commonWriting } from '../../../utils/Style/Global/Global_Style';
import { navHeight, responsiveWidthMobile } from '../../../utils/Constant';

const StyledSpan = styled.span`
    ${commonWriting};

`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${navHeight}px;
  width: ${navHeight}px;

  margin-right: 10px;
`;

const Logo = styled.img`
  height: ${navHeight}px;
  width: ${navHeight}px;
  
`;

function Name () {
  const [logoSrc, setLogoSrc] = useState(LogoCompass);
  const [layoutMobile, setLayoutMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      console.log('Window resized!');
      if (window.innerWidth <= responsiveWidthMobile) {
        setLayoutMobile(true);
      } else {
        setLayoutMobile(false);
      }
    };
    if (window.innerWidth <= responsiveWidthMobile) {
      setLayoutMobile(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return (
    <NameWrapper
      onMouseEnter={() => setLogoSrc(LogoCompassHover)}
      onMouseLeave={() => setLogoSrc(LogoCompass)}
    >
      <LogoContainer>
        <Logo src={logoSrc} alt={'Alibert Jimmy'}/>
      </LogoContainer>
      {
        layoutMobile
          ? <></>
          : <StyledSpan>Jimmy.Dev</StyledSpan>
      }

    </NameWrapper>
  );
}

export default Name;
