// Import Style
import styled from 'styled-components';

// Import Constants
import { footerSideDivWidth, responsiveWidthMobile } from '../../Constant';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: black;
  background-color: white;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  @media (max-width: ${responsiveWidthMobile}px){
      font-size: 15px;
      flex-direction: column;
  }
`;

export const CopyrightContainer = styled.div`
  text-align:center;
  margin: 20px 0;
`;

export const ScrollToTopButtonContainer = styled.div`
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: ${footerSideDivWidth}px;
`;

export const ScrollToTopButton = styled.button`
  padding-right: 20px;

  border: none;
  
  cursor: pointer;

  background-color: transparent;    
`;

export const MobileDisplayWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;
