// Import PropTypes
import PropTypes from 'prop-types';

// Import Assets
import LesBoisFlottesPreview from '../assets/Site_Preview_Illustration/LesBoisFlottes_Preview.jpg';
import PackPlannerPreview from '../assets/Site_Preview_Illustration/PackPlanner_Preview.jpg';
import AzurPreview from '../assets/Site_Preview_Illustration/Azur_Preview.jpg';

export const SitePreviewDatasList = [
  {
    title: 'SeasonalRental-Title',
    description:
      [
        'SeasonalRental-Description1', 'SeasonalRental-Description2'
      ],
    tech:
      {
        UserInterface: ['React', 'CSS', 'Styled'],
        Server: ['Node.js', 'Express'],
        Database: []
      },
    linkGit: '',
    linkLive: 'https://www.lesboisflotteslaverne.fr',
    illustration: LesBoisFlottesPreview,
    illustrationHeight: 4224
  },
  {
    title: 'LogisticsInventory-Title',
    description:
      [
        'LogisticsInventory-Description1', 'LogisticsInventory-Description2', 'LogisticsInventory-Description3'
      ],
    tech:
      {
        UserInterface: ['React', 'CSS', 'Styled'],
        Server: ['Node.js', 'Express'],
        Database: ['MongoDB', 'S3 Bucket']
      },
    linkGit: '',
    linkLive: 'https://www.pack-planner.com',
    illustration: PackPlannerPreview,
    illustrationHeight: 2545
  },
  {
    title: 'CoachingServices-Title',
    description:
      [
        'CoachingServices-Description1'
      ],
    tech:
      {
        UserInterface: ['React', 'CSS', 'Styled'],
        Server: [],
        Database: []
      },
    linkGit: 'https://github.com/AlibertJimmy/AzurCoaching',
    linkLive: 'https://main.dll4k4ynnuc4x.amplifyapp.com/',
    illustration: AzurPreview
  }
];

export const SitePreviewDatasListPropTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fonctionnality: PropTypes.string.isRequired,
  tech: PropTypes.arrayOf(
    PropTypes.shape({
      li: PropTypes.string.isRequired
    })
  ).isRequired,
  libraries: PropTypes.arrayOf(
    PropTypes.shape({
      li: PropTypes.string.isRequired
    })
  ).isRequired,
  linkGit: PropTypes.string.isRequired,
  linkLive: PropTypes.string.isRequired,
  illustration: PropTypes.string.isRequired
});
