/*
export const borderRadiusSequence = [
  // TopLeft TopRight BottomRight BottomLeft
  '55% 70% 55% 55%',
  '55% 55% 70% 55%',
  '55% 55% 55% 70%',
  '70% 55% 55% 55%'
];
*/

export const borderRadiusSequence = [
  // TopLeft TopRight BottomRight BottomLeft
  '10% 10% 10% 10%',
  '15% 15% 15% 15%',
  '20% 20% 20% 20%',
  '25% 25% 25% 25%',
  '20% 20% 20% 20%',
  '15% 15% 15% 15%',
  '10% 10% 10% 10%',
  '05% 05% 05% 05%'
];

export function deformBorders (profilePicture, counter) {
  profilePicture.style.borderRadius = borderRadiusSequence[counter];
};
