// Import React Libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
import Preview from '../../components/Preview/Preview';
// Import Datas
import { SitePreviewDatasList } from '../../datas/sites';

// Import Style
import { SectionTitle } from '../../utils/Style/Global/Global_Style';
import { SectionContainer, SectionWrapper } from '../../utils/Styles';

// Import Colors
import colors from '../../utils/Colors/Colors';

function Projects () {
  const { i18n, t } = useTranslation();

  useEffect(() => {
  }, [i18n.language]);

  return (
    <SectionWrapper id={`${t('Projects')}`} style={ { backgroundColor: `${colors.unevenSections}` } }>
      <SectionTitle>{t('Projects')}</SectionTitle>
      <SectionContainer>
          <Preview SiteDatasList={SitePreviewDatasList}/>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default Projects;
