export function pathWithoutLang () {
  const hash = window.location.hash;
  if (hash) {
    return hash.substring(1); // Remove the leading '#' and return the rest
  }
  return '';
};

export function translatePath (path, t) {
  // console.log(`path: ${path}`);
  const pathParts = path.split('/');
  const translatedPath = pathParts.length > 1 ? pathParts.map(t).join('/') : t(path);

  // console.log(`translatePath: ${translatePath}`);
  return translatedPath;
};
