// Import React Libraries
import React from 'react';

// Import Assets
import HTML from '../../assets/Skill_Icon/HTML.png';
import CSS from '../../assets/Skill_Icon/CSS.png';
import JavaScriptIcon from '../../assets/Skill_Icon/JavaScript.png';
import ReactIcon from '../../assets/Skill_Icon/React.png';

// Import Style
import {
  TechStackContainer,
  TechStackP, TextContainer,
  TechStackIconWrapper, TechStackIconContainer, TechIcon
} from '../../utils/Style/TechStackStyle';

function TechStackComponent () {
  return (
    <TechStackContainer id='techStackContainer'>
      <TextContainer id='techStackTextContainer'>
        <TechStackP>Tech Stack</TechStackP>
      </TextContainer>
      <TechStackIconWrapper>
        <TechStackIconContainer>
          <TechIcon src={HTML} alt='HTML'/>
          <TechIcon src={CSS} alt='CSS'/>
        </TechStackIconContainer>
        <TechStackIconContainer>
          <TechIcon src={JavaScriptIcon} alt='JavaScript'/>
          <TechIcon src={ReactIcon} alt='React'/>
        </TechStackIconContainer>
      </TechStackIconWrapper>
    </TechStackContainer>

  );
}
export default TechStackComponent;
